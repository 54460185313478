import React from "react"
import { Container, Row } from "reactstrap"
// import { Link } from "gatsby"

const Hero = () => {
  return (
    <Container>
      <Row className="hero-wrap">
        <div className="col-md-10 mx-auto text-center">
          <h1 className="hero-title-big mb-4">Information Technology</h1>
          <span
            className="text-uppercase phs"
            style={{
              letterSpacing: "10.64px",
              color: "#fff",
              fontSize: "1.5rem",
            }}
          >
            World-class ERP, CRM, and data staffing <br />
            and recruitment – guaranteed.
          </span>

          {/*   <div className="view-awards">
                   <Link className="light-btn-yellow" to="#">
                     View Awards
                   </Link>
                 </div>*/}
        </div>
      </Row>
    </Container>
  )
}
export default Hero
