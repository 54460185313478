import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import marked from "marked"
// import SampleForm from "../components/form/form"
import Area from "../components/section/Area"
// import { LazyLoadImage } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"
import Hero from "../components/hero"
import WebriqForm from "../components/form/form"
import Find from "../components/section/Find"

// import network1 from "../images/linkedin.png"
// import network2 from "../images/fb-icon.png"
// import network3 from "../images/twitter_icon.png"

// import phoneDot from "../images/phoneDot.png"

// import whoAreWe from "../images/whoAreWe.png"
// import whyPeerSrc from "../images/whyPeerSrc.png"
// import whatSeparateUs from "../images/whatSeparateUs.png"

// import LookingIT from "../images/looking-for-IT.png"
// import searchJobs from "../images/searchJobs.png"
// import peerNetwork from "../images/peerNetwork.png"

// import testi1 from "../images/testi1.png"
// import testi2 from "../images/testi2.png"
// import testi3 from "../images/testi3.png"

// import WhyChoose from "../components/section/WhyChoose"

// Our Services Section
import shake from "../images/shakehands.png"
import shake2 from "../images/shake2.png"
import girl from "../images/contract-hire.jpg"
import writing from "../images/writing-book.png"
import oracle from "../images/oracle.png"
// import BlockContent from "@sanity/block-content-to-react"

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allSanityPost.nodes

  useEffect(() => {
    const initializeWidget = () => {
      if (window.elfsightAPI && typeof window.elfsightAPI.init === "function") {
        window.elfsightAPI.init()
      } else {
        setTimeout(initializeWidget, 100)
      }
    }

    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.defer = true
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDescription} />
      <div className="hero-section bg-light">
        <video className="hero-vid" width="100%" muted autoPlay>
          <source
            // src="https://res.cloudinary.com/dwgikyj1p/video/upload/v1613025353/video_nd6hyt.mp4"
            src="https://res.cloudinary.com/dwzjvi83z/video/upload/v1615007527/video_nd6hyt_z47zrg.mp4"
            type="video/mp4"
          />
          <track src="" kind="captions" srclang="en" label="English"></track>
          Your browser does not support the video tag.
        </video>
        <Hero />
        <div id="advertise">
          <Container>
            <Find />
          </Container>
        </div>
      </div>

      <div id="our-services">
        <Container fluid>
          <h3 className="text-center my-5 pb-3 text-uppercase position-relative">
            Our Services
          </h3>
          <Row className="justify-content-center text-center mb-4">
            <Col lg={4} md={3} sm={6} className="col-xs-10">
              <div className="h-100">
                <img
                  width="100%"
                  fluid
                  src={writing}
                  className="img-fluid"
                  alt="services"
                />
                <div className="bg-white">
                  <h6>CONTRACT</h6>
                  <p>
                    Engage our technology experts on a short- or long-term basis
                    to get important work done. Our contractors serve as an
                    extension of your team, giving you the ability to scale as
                    needed without the need for full-time hires.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={3} sm={6} className="col-xs-10">
              <div className="h-100">
                <img
                  width="100%"
                  fluid
                  src={girl}
                  className="img-fluid"
                  alt="services"
                />
                <div className="bg-white">
                  <h6>CONTRACT-TO-HIRE</h6>
                  <p>
                    Evaluate a contractor on the job before deciding whether to
                    bring them on permanently. Get to know a candidate’s skills,
                    work ethic, and cultural alignment during a trial period.
                    Then, if it’s a great fit, we’ll make the transition to your
                    payroll seamless.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={3} sm={6} className="col-xs-10">
              <div className="h-100">
                <img
                  width="100%"
                  fluid
                  src={shake2}
                  className="img-fluid"
                  alt="services"
                />
                <div className="bg-white">
                  <h6>DIRECT HIRE</h6>
                  <p>
                    Find talented data, ERP, and CRM professionals and grow your
                    team. We’ll handle sourcing, screening, and interviewing –
                    all you need to do is choose the best fit from our list of
                    highly qualified candidates.
                  </p>
                </div>
              </div>
            </Col>
            {/* <Col lg={2} md={4} sm={6} className="col-xs-15">
              <div className="h-100">
                <img
                  width="100%"
                  fluid
                  src={shake}
                  className="img-fluid"
                  alt="services"
                />
                <div className="bg-white">
                  <h6>PAYROLL SERVICES</h6>
                  <p>
                    PeerSource offers comprehensive payroll services in
                    partnership with our sister company OnePoint Employer
                    Solutions.
                  </p>
                </div>
              </div>
            </Col> */}
          </Row>
          <div className="relative text-center">
            <Link className="common-btn block" to="/contact-us">
              Hire Now
            </Link>
          </div>
        </Container>
      </div>

      <Container>
        <div className="py-100">
          <Row className="justify-content-center text-center">
            <Col lg={9}>
              <h2>Expertise in Action</h2>
              <p>
                At PeerSource, we specialize in helping your company achieve the
                full potential of your data and ERP/CRM initiatives. Our team
                embodies a dynamic blend of integrity, entrepreneurial spirit,
                out-of-the-box thinking, and technical acumen. It’s that
                combination that enables us to deliver unparalleled service to
                our client partners.
              </p>
              <p>
                Powered by our agile recruiting process, we offer a
                relationship-driven approach that ensures we find the perfect
                fit for your needs. From analysts to engineers, administrators
                to consultants, and even executive leadership talent – our
                1-year guarantee with Lifetime Placement Assurance ensures you
                get the right long-term match.
              </p>
              <Link className="common-btn block relative mb-5" to="/contact-us">
                Request Talent
              </Link>
            </Col>
          </Row>
        </div>
      </Container>

      {/* <div id="about-section">
        <Container>
          <Row>
            <Col md={6}>
              <div className="bg-tp">
                <h3 className="text-uppercase position-relative">
                  about peersource
                </h3>

                <h6>Who are we?</h6>
                <p>
                  PeerSource is a Technology/IT, Technical Sales, and Accounting
                  recruiting firm that services all 50 states. Since 2011, we’ve
                  been providing both our clients and our candidates with
                  excellent service in the Contract, Contract-to-Hire, and
                  Direct Hire recruiting space.
                </p>

                <h6>What separates us?</h6>
                <p>
                  We go above and beyond the typical transaction-focused
                  recruiting firms that are so prevalent today. We care about
                  building relationships with everyone involved, from nailing
                  down exactly what our clients’ needs are, to matching those
                  needs with the perfect candidate based on what they’re looking
                  for in their career. We also have a team of Subject Matter
                  Experts in various IT disciplines that we leverage to find and
                  qualify the best possible candidates.
                </p>

                <h6>Why PeerSource?</h6>
                <p>
                  We’ve served over a hundred clients in many different
                  industries. We’ve successfully recruited individuals across
                  all of IT including Software Developers, Product Managers,
                  Network Engineers, ERP Consultants, and Database
                  Administrators. Through our sister company, we have over
                  10,000 employees on our large group health plan which allows
                  us to provide our contractors with benefits that are
                  second-to-none, attracting and retaining contractor talent for
                  the long-term.
                </p>
                <Link className="common-btn" to="/about-us">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      <Area />

      <div className="google-review home-review">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center mb-5">Testimonials</h2>
            <div
              className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
              data-elfsight-app-lazy
              // data-embed-id="25369363"
            ></div>
            {/* <div
              className="sk-ww-google-reviews"
              data-embed-id="25369363"
            ></div> */}
            {/* <div className="overlay" /> */}
          </div>
        </Container>
        {/* <div className="overlay" /> */}
      </div>

      <div id="news" className="py-100 blog-section">
        <Container className="latestNews">
          <Row className="mt-5 justify-content-center">
            <Col lg={3} md={12} className="mb-5">
              <h2 className="position-relative mb-4">Featured Posts</h2>

              <div className="mb-5">
                {" "}
                <Link to="/news" className="blue-link ">
                  from our blog
                </Link>
              </div>

              <h6>
                Sign-up to be notified <br />
                of new posts?
              </h6>

              <WebriqForm
                name="Subscribe Blog"
                className="webriq-form"
                form_id="7050eedd-8d8f-4ed8-b5de-f00c71644cc8"
              >
                <div class="form-group">
                  <label className="d-none" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Your Email Here..."
                    name="email"
                    id="email"
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <button className="common-btn border-none" type="submit">
                  Send
                </button>
              </WebriqForm>
            </Col>
            <Col lg={9} md={12}>
              <Row>
                {posts.map((post, index) => (
                  <Col md={6} className="mb-5" key={index}>
                    <Link to={`/${post.slug?.current}`}>
                      <div className="blog-item bg-light">
                        {/*<LazyLoadImage
                          className="img-fluid mb-5"
                          src={post.mainImage?.asset?.fluid?.src}
                          alt={post.mainImage?.alt}
                        />
                        */}

                        <div
                          className="bg-blog position-relative"
                          style={{
                            backgroundImage: `url(${post.mainImage?.asset?.fluid?.src})`,
                          }}
                        >
                          <div className="blog-date">
                            <p>
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {post.publishedAt}
                            </p>
                          </div>
                        </div>

                        <div className="blog-body">
                          <h3 className="mb-4">{post.title}</h3>
                          <div
                            className="blog-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: marked(post.excerpt),
                            }}
                          />
                        </div>

                        <div className="blog-foot">
                          <Row>
                            <Col md={7}>
                              {post.authors.length !== 0 && (
                                <p>
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  {post.authors[0].person.name}
                                </p>
                              )}
                            </Col>
                            <Col md={5} className="text-right">
                              <p>
                                <Link
                                  to={`/${post?.slug?.current}`}
                                  style={{ color: "#00C1C8" }}
                                >
                                  <small>
                                    Read More{" "}
                                    <i
                                      className="fa fa-arrow-right"
                                      ariaHidden="true"
                                    ></i>
                                  </small>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="give-tody" className="py-100">
        <Container>
          <Row>
            <Col md={7}>
              <h3>
                Give us a call today to find out how PeerSource can help YOU.
              </h3>
              <span>
                We work across the entire US and staff in all 50 states.
              </span>
              <p className="mt-3">
                <a href="tel:303-867-1111" target="_blank" rel="noreferrer">
                  303-867-1111
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }

    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 2) {
      nodes {
        id
        title
        publishedAt(formatString: "MMM-DD-YYYY")
        mainImage {
          asset {
            fluid {
              src
            }
          }
          alt
        }
        excerpt
        slug {
          current
        }
        authors {
          person {
            name
          }
        }
      }
    }
  }
`
